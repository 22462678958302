import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const fetchProjects = createAsyncThunk(
    'projects/fetch',
    async ({ uuid, skillName, skillLevel, is_curation, old_uid }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_extra', {
                    uid:uuid, skill_name: skillName, skill_difficulty: skillLevel, is_curation: is_curation, old_uid: old_uid
                });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;

            } catch (err) {
                console.log(err);
                retries += 1;
            }
        }
        toast.error('Error in fetching projects. Please try again later.');
    })


const initialState = {
    projectsDetails: {},
    loading: false
}

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjectsAndOutcomes: (state, action) => {
            state.projectsDetails = action.payload
        },
        resetProjectsData: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjects.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.loading = false
            state.projectsDetails = action.payload
        });
    },
});

export const { setProjectsAndOutcomes, resetProjectsData } = projectsSlice.actions
export const selectProjects = (state) => state.projects?.projectsDetails
export const selectLoadingStatus = (state) => state.projects?.loading
export default projectsSlice.reducer;
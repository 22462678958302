import { logoutUser } from '../redux/authenticationSlice';
import store from '../store';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const axiosAuth = axios.create({
   
});

export const axiosXanoAuth = axios.create({

})

axiosXanoAuth.interceptors.request.use((config) => {
    const token = store.getState().roadMapDetails.signUpDetails.authToken;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axiosAuth.interceptors.request.use((config) => {
    const token = store.getState().authentication.token;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axiosAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { status } = error.response;
        if (status === 401) {
            // Token expired or unauthorized, handle the logout and redirect to login page
            await store.dispatch(logoutUser());
            const navigate = useNavigate() // Dispatch a logout action to clear the token from the store
            // Redirect to the login page
            navigate('/welcome'); // You can use your preferred method of navigation here
    }
        return Promise.reject(error);
});

axiosXanoAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { status } = error.response;
        if (status === 401) {
            // Token expired or unauthorized, handle the logout and redirect to login page
            await store.dispatch(logoutUser());
            const navigate = useNavigate() // Dispatch a logout action to clear the token from the store
            // Redirect to the login page
            navigate('/ffe-signup/welcome'); // You can use your preferred method of navigation here
        }
        return Promise.reject(error);
});
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

// Action to fetch questions asynchronously
export const fetchAnalysisforAll = createAsyncThunk(
    'analysis/fetchForAll',
    async ({ question, answer, uid, step }) => {
      const maxRetries = 3;
      let retries = 0;
      while (retries < maxRetries) {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/answer_analysis', { question:question, answer:answer, uid:uid });
            if (response.data === true || response.data === false) {
                window.open('https://personalise.learntube.ai')
                return;
            }
            return {step: step, question: question, answer: answer, analysis:response.data};
        } catch (err) {
            console.log(err);
            retries += 1;
        }
    }
    toast.error('Error in fetching analysis. Please try again later.');
  }
);

export const fetchAnalysis = createAsyncThunk(
    'analysis/fetch',
    async ({ question, answer, uid, name, step }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries){
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/answer_analysis', { question:question, answer:answer, uid:uid, name:name });
                if (response.data === true || response.data === false) {
                    window.open('https://personalise.learntube.ai')
                    return;
                }
                return {step: step, question: question, answer: answer, analysis:response.data};
            } catch (err) {
                console.log(err);
                retries += 1;
            }
        }
        toast.error('Error in fetching analysis. Please try again later.');
    }
);

const initialState = []
// Redux slice for questions
const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        resetAnalysis: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAnalysis.fulfilled, (state, action) => {
            return [...state, action.payload];
        });

        builder.addCase(fetchAnalysisforAll.fulfilled, (state, action) => {
            return [...state, action.payload];
        });
    },
});

export const { resetAnalysis } = analysisSlice.actions
export const selectAnalysis = (state) => state.analysis;
export default analysisSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Action to fetch questions asynchronously
export const fetchQuestions = createAsyncThunk(
    'questions/fetch',
    async ({ skillName, skillLevel, utm_source, utm_medium, utm_campaign }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/q_generation', { skill_name:skillName, skill_difficulty:skillLevel, utm_source:utm_source, utm_medium:utm_medium, utm_campaign: utm_campaign });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
    }
        toast.error('Error in fetching questions. Please try again later.');
    });

const initialState = {
    uuid: '',
    question_list: []
}
// Redux slice for questions
const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        resetQuestions: (state) => initialState,
        setUuid: (state, action) => {
            state.uuid = action.payload
        }
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQuestions.fulfilled, (state, action) => {
            const { uuid, question_list } = action.payload
            state.uuid = uuid
            state.question_list = question_list
        });
    },
});


export const { resetQuestions, setUuid } = questionsSlice.actions
export const selectQuestions = (state) => state.questions.question_list;
export const uuidNo = (state) => state.questions.uuid;
export default questionsSlice.reducer;
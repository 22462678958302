import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { axiosXanoAuth } from "../config/axiosInstance"
import { toast } from "react-toastify"

const POLLING_INITIAL_DELAY = 5*1000;
const POLLING_INTERVAL = 2*1000

const pollAPI = async ({ user_roadmap_id }) => {
    try {
        const response = await axiosXanoAuth.get('https://xgfy-czuw-092q.m2.xano.io/api:KuSR8Gl1/user_roadmap/generation_status', { 
            params: {
                user_roadmap_id 
            }});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkRoadMapStatus = createAsyncThunk(
    'checkRoadMapStatus',
    async ({ user_roadmap_id }) => {      
        try {
            await new Promise(resolve => setTimeout(resolve, POLLING_INITIAL_DELAY));
            while (true) {
                const response = await pollAPI({ user_roadmap_id });
                if (response?.data?.roadmap_generation_status === 'complete') {
                    return response;
                }
            await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
        }
        } catch (error) {
            return error?.message;
        }
    }
);

export const checkEmailAndPhone = createAsyncThunk(
    'checkEmailAndPhone',
    async ({ email, phone_number }) => {
        const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:auth-service/authorize/check_unique_account', {
            params: {
                email,
                phone_number
            }
        })
        return response.data
    }
)

export const signUpUser = createAsyncThunk(
    'signUpUser',
    async ({ email, password, name, phone_number, utm_campaign }) => {
        const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:auth-service/authorize', {
            signup_type: "email",
            email,
            password,
            name,
            phone_number,
            signup_medium: "webapp",
            campaign: 'ffe',
            signup_page_url: `https://learntube.ai/?utm_source=Partnership&utm_medium=FFE&utm_campaign=${utm_campaign}`,
            referral_id: "",
            referral_type: "",
            college_id: "",
            is_login: false,

        })
        return response.data
    }
)

export const fetchRoadMapUserDetails = createAsyncThunk(
    'fetchRoadMapUserDetails',
    async ({ authToken }) => {
        const response = await axiosXanoAuth.get('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/auth/me', {
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        })
        return response.data
    }
)

export const fetchRoadMapRelatedJobs = createAsyncThunk(
    'fetchRoadMapRelatedJobs',
    async ({ subject }) => {
        try {
            const response = await axios.post('https://certified-backend.learntube.ai/job_names', {
                subject
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchDomianAndJobList = createAsyncThunk(
    'fetchDomianAndJobList',
    async () => {
        try {
            const response = await axios.get('https://ninjacurate.learntube.ai/roadmaps/catalogue')
            return response.data?.data ?? []
        } catch (error) {
            toast.error('Something went wrong')
            console.log(error.message)
        }
    }
)

export const searchJobRole = createAsyncThunk(
    'searchJobRole',
    async ({ job_role }) => {
        try {
            const response = await axios.get('https://ninjacurate.learntube.ai/roadmaps/fast_search', {
                params: {
                    job_role
                }
            })
            return response.data?.data
        } catch (error) {
            toast.error('Something Went Wrong')
            console.log(error.message)
        }
    }
)

export const generateRoadMapId = createAsyncThunk(
    'generateRoadMapId',
    async ({ roadmap_goal }) => {
        try {
            const response = await axiosXanoAuth.post('https://xgfy-czuw-092q.m2.xano.io/api:KuSR8Gl1/user_roadmap/new', {
                roadmap_goal, is_collab: true
            })
            return response.data
        } catch (error) {
            toast.error('Something Went Wrong')
            console.log(error.message)
        }
    }
)

export const generateRoadMap = createAsyncThunk(
    'generateRoadMap',
    async ({  job_role }) => {
        try {
            const response = await axios.get('https://ninjacurate.learntube.ai/roadmaps/job_role_fetch', {
                params: {
                    job_role
                }
            })
            return response.data?.data
        } catch (error) {
            toast.error('Something Went Wrong')
            console.log(error.message)
        }
    }
)

export const fetchCourseSyllabus = createAsyncThunk(
    'fetchCourseSyllabus',
    async ({ subject, goal }) => {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/addon_course_bundle_syllabus',{ subject, goal })
            return response.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const checkUserInWhiteList = createAsyncThunk(
    'checkUserInWhiteList',
    async ({ email }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:auth-service/authorize-collab/ffe_whitelist', {
                email
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const markUserOnboarding = createAsyncThunk(
    'markUserOnboarding',
    async () => {
        try {
            const response = await axiosXanoAuth.post('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/onboarding/mark_onboarded')
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const markUserAsActivated = createAsyncThunk(
    'markUserAsActivated',
    async () => {
        try {
            const response = await axiosXanoAuth.post('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/activation/mark_activated')
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const publishRoadmap = createAsyncThunk(
    'publishRoadmap',
    async ({ user_roadmap_id }) => {
        try {
            const response = await axiosXanoAuth.post('https://xgfy-czuw-092q.m2.xano.io/api:KuSR8Gl1/user_roadmap/publish', {
                user_roadmap_id
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const markRoadmapAsClaimed = createAsyncThunk(
    'publishRoadmap',
    async ({ email }) => {
        try {
            const response = await axiosXanoAuth.post('https://xgfy-czuw-092q.m2.xano.io/api:KuSR8Gl1/roadmap/ffe/mark_claimed', {
                email
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getUserRoadmap = createAsyncThunk(
    'getUserRoadmap',
    async ({ user_roadmap_id }) => {
        try {
            const response = await axiosXanoAuth.get('https://xgfy-czuw-092q.m2.xano.io/api:KuSR8Gl1/user_roadmap', {
                params: {
                    user_roadmap_id
                }
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    userDetails: {
        name: '',
        email: '',
        phoneNo: '',
        utm_campaign: ''
    },
    emailAndPhoneExist: {},
    signUpDetails: {
        authToken: ''
    },
    userAccountDetails: {},
    relatedJobs: [],
    domainAndJobList: {},
    searchResults: [],
    roadMapDetails: [],
    jobRole: '',
    courseSyllabus: {
        skillName: '',
        rating: 0,
        students: 0,
        syllabus: {},
        loading: false
    },
    roadMapId: {},
    checkWhiteListUser: {},
    roadMapStatus: {},
    userGeneratedRoadMap: {},
    step: 1,
    domainName: '',
    searchQuery: ''
}

const getRandomRating = () => Math.floor(Math.random() * 2) + 4
const getRandomStudents = () => Math.floor(Math.random() * 501) + 1300

const roadMapSlice = createSlice({
    name: 'roadmap',
    initialState,
    reducers: {
        setRoadMapUserDetails: (state, action) => {
            const { name, email, phoneNo } = action.payload
            state.userDetails.name = name
            state.userDetails.email = email
            state.userDetails.phoneNo = phoneNo
        },
        setRoadmapUtmCampaign: (state, action) => {
            state.userDetails.utm_campaign = action.payload
        },
        setSearchResults: (state) => {
            state.searchResults = []
        },
        setSelectedJobRole: (state, action) => {
            state.jobRole = action.payload
        },
        setStudentRatingAndSkillName: (state, action) => {
            const { rating, students, skillName } = action.payload
            state.courseSyllabus.rating = rating
            state.courseSyllabus.skillName = skillName
            state.courseSyllabus.students = students
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setDomainName: (state, action) => {
            state.domainName = action.payload
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
        resetRoadMapDetails: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(checkEmailAndPhone.fulfilled, (state, action) => {
            state.emailAndPhoneExist = action.payload
        })
        builder.addCase(signUpUser.fulfilled, (state, action) => {
            state.signUpDetails = action.payload
        })
        builder.addCase(fetchRoadMapUserDetails.fulfilled, (state, action) => {
            state.userAccountDetails = action.payload
        })
        builder.addCase(fetchRoadMapRelatedJobs.fulfilled, (state, action) => {
            state.relatedJobs = action.payload
        })
        builder.addCase(fetchDomianAndJobList.fulfilled, (state, action) => {
            state.domainAndJobList = action.payload
        })
        builder.addCase(searchJobRole.fulfilled, (state, action) => {
            state.searchResults = action.payload
        })
        builder.addCase(generateRoadMap.fulfilled, (state, action) => {
            if (Array.isArray(action.payload)) {
                state.roadMapDetails = action.payload.map(skill => ({
                    skillName: skill,
                    rating: getRandomRating(),
                    students: getRandomStudents(),
                }));
            } else {
                state.roadMapDetails = [];
            }
        })
        builder.addCase(fetchCourseSyllabus.pending, (state) => {
            state.courseSyllabus.loading = true
            state.courseSyllabus.syllabus = {}
        })
        builder.addCase(fetchCourseSyllabus.fulfilled, (state, action) => {
            state.courseSyllabus.loading = false
            state.courseSyllabus.syllabus = action.payload
        })
        builder.addCase(fetchCourseSyllabus.rejected, (state) => {
            state.courseSyllabus.loading = false
            state.courseSyllabus.syllabus = {}
        })
        builder.addCase(checkUserInWhiteList.fulfilled, (state, action) => {
            state.checkWhiteListUser = action.payload
            state.signUpDetails.authToken = action.payload?.authToken
        })
        builder.addCase(generateRoadMapId.fulfilled, (state, action) => {
            state.roadMapId = action.payload
        })
        builder.addCase(checkRoadMapStatus.fulfilled, (state, action) => {
            state.roadMapStatus = action.payload
        })
        builder.addCase(getUserRoadmap.fulfilled, (state, action) => {
            state.userGeneratedRoadMap  = action.payload
        })
    }
})

export const { setRoadMapUserDetails, setRoadmapUtmCampaign, setSearchResults, setSelectedJobRole, setStudentRatingAndSkillName, resetRoadMapDetails, setStep, setDomainName, setSearchQuery } = roadMapSlice.actions
export const selectRoadMapUserDetails = (state) => state.roadMapDetails.userDetails
export const selectSignUpDetails = (state) => state.roadMapDetails.signUpDetails
export const selectDomainAndJobList = (state) => state.roadMapDetails.domainAndJobList
export const selectJobRoleSearchResults = (state) => state.roadMapDetails.searchResults
export const selectRoadmapRelatedJobs = (state) => state.roadMapDetails.relatedJobs
export const selectRoadmap = (state) => state.roadMapDetails.roadMapDetails
export const selectJobRole = (state) => state.roadMapDetails.jobRole
export const selectCourseSyllabus = (state) => state.roadMapDetails.courseSyllabus
export const selectUserAccountDetails = (state) => state.roadMapDetails.userAccountDetails
export const selectRoadmapStatus = (state) => state.roadMapDetails.roadMapStatus
export const selectRoadmapId = (state) => state.roadMapDetails.roadMapId
export const selectGeneratedRoadmap = (state) => state.roadMapDetails.userGeneratedRoadMap
export const selectStep = (state) => state.roadMapDetails.step
export const selectDomain = (state) => state.roadMapDetails.domainName
export const selectSearchQuery = (state) => state.roadMapDetails.searchQuery
export const selectWhiteListStatus = (state) => state.roadMapDetails.checkWhiteListUser
export default roadMapSlice.reducer
import React from 'react'

const Logos = () => {
  
  return (
    <div className='w-auto h-auto mt-4 lg:mt-6 bg-white flex items-center mx-auto rounded-md mb-4 py-1 pr-2'>
        <div className='w-[1.75rem] h-[1.75rem] mx-1.5'>
            <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/image_11.png?updatedAt=1685175860985" loading="eager" alt="" className='w-full h-full'/>
        </div>
        <div className=''>
            <p className='font-medium text-sm'>LearnTube</p>
            <p className='text-xs -mt-1'>by CareerNinja</p>
        </div>
    </div>
  )
}

export default Logos
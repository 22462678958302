import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logos from "../../components/Logos";
import React, {useEffect} from 'react';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { animateScroll as scroll } from 'react-scroll'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Layout = () => {

    const { pathname } = useLocation()

    const navigate = useNavigate()

    useEffect(() => {
        scroll.scrollToTop();
    }, []);
  
    const backButton = pathname === '/ffe-signup/roadmap-details'
    
    return (
        <div  className={`w-full mx-auto h-[100dvh] flex flex-col overflow-y-scroll`} style={{background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'}}>
            <ToastContainer />
            <div className={`${backButton && 'max-w-2xl'} w-full mx-auto flex items-center px-4`}>
                {backButton && <FontAwesomeIcon icon={faChevronLeft} className="text-xl text-white cursor-pointer" onClick={() => navigate(-1)}/>}
                <Logos />
            </div>
            <Outlet />
        </div>
  )
}

export default Layout